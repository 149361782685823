import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext, useRef } from "react";
import SlotNumber from "../SlotNumber/SlotNumber.js";

import { initMercadoPago, Payment, StatusScreen } from "@mercadopago/sdk-react";
import { createRef } from "react";
import Loader from "../Loader/Loader.js";
import { QRCode } from "react-qrcode-logo";
import CopyDiv from "../CopyDiv/CopyDiv.js";

import { UserContext } from "../../App.js";
import { CSVLink } from "react-csv";
import ReactInputMask from "react-input-mask";
import Einstein from "../Einstein/Einstein.js";

import { userConfigs } from "../../util/config.js";

import Cookies from "js-cookie";
import Popup from "../../util/popup.jsx";

const fileTypes = ["PFX"];

const mercadoPagoKey = "APP_USR-6c9ed7f4-b75a-485f-b125-294cd72c828e";

function Navbar(props) {
    // useEffect(() => {
    //     setTimeout(() => {
    //         Popup.fire({
    //             title: "Aviso importante!",
    //             html: `<div>
    //                     Estamos sem energia devido a uma manutenção programada
    //                     pela prefeitura em nossa quadra, com <strong>previsão de
    //                     restabelecimento por volta das 16h</strong>. Durante esse
    //                     período, nossa <strong>operação ficará temporariamente
    //                     interrompida</strong>, impossibilitando o processamento e
    //                     atendimento dos pedidos. Pedimos a compreensão de todos
    //                     e estamos à disposição para esclarecer dúvidas.
    //                 </div>`,
    //             customClass: {
    //                 popup: "aviso-popup",
    //                 confirmButton: "custom-swal-button",
    //             },
    //         });
    //     }, 2000);
    // }, []);

    const navigate = useNavigate();

    const location = useLocation();

    const { user, setUser, orders, integrations, modals, hidden } =
        useContext(UserContext);

    const [isTurned, setTurned] = useState(0);
    const [modalConfig, setModalConfig] = useState(false);
    modals.current.setModalConfig = setModalConfig;
    const [modalAccount, setModalAccount] = useState(false);
    modals.current.setModalAccount = setModalAccount;
    const [modalWallet, setModalWallet] = useState(0);
    modals.current.setModalWallet = setModalWallet;
    const [modalExtract, setModalExtract] = useState(false);
    modals.current.setModalExtract = setModalExtract;
    const [walletValue, setWalletValue] = useState("");
    modals.current.setWalletValue = setWalletValue;

    const [modalUser, setModalUser] = useState({});

    const [config, setConfig] = useState(user?.config || {});

    const [modalCode, setModalCode] = useState(false);
    const [code, setCode] = useState("");
    const [codeLoading, setCodeLoading] = useState(false);

    const [extract, setExtract] = useState(null);

    const [showPopup, setShowPopup] = useState(false); //Gab

    const [minCredits, setMinCredits] = useState(50);
    modals.current.minCredits = 50;

    const [faturadorPopup, setFaturadorPopup] = useState(false);

    const [cert, setCert] = useState(null);
    const [certPassword, setCertPassword] = useState("");
    const [certLoading, setCertLoading] = useState(false);

    // Umas coisas da Gab

    const calculateVencimento = (createdAt) => {
        // Transforma a string da data em um objeto Date
        const creationDate = new Date(createdAt);

        // Adiciona 1 ano à data de criação
        const vencimentoDate = new Date(creationDate);
        vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

        // Formata a data para o padrão 'dd/MM/yyyy' no Brasil
        return vencimentoDate.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "long",
            year: "numeric",
        });
    };

    const calculateVencimentoAbreviado = (createdAt) => {
        const vencimentoDate = new Date(createdAt);
        vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

        return vencimentoDate.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit", // Mês abreviado (números)
            year: "numeric",
        });
    };

    const calculateRenewalValue = (createdAt) => {
        const creationDate = new Date(createdAt);

        // Datas de corte
        const date1 = new Date("2023-12-25");
        const date2 = new Date("2024-07-21");
        const date3 = new Date("2024-10-01");

        // Verifica em qual faixa de data o cadastro se encaixa
        if (creationDate >= date3) {
            return "R$ 1.999,90";
        } else if (creationDate >= date2) {
            return "R$ 1.599,90";
        } else if (creationDate >= date1) {
            return "R$ 1.199,90";
        } else {
            return "R$ 1.199,90";
        }
    };

    const calculateDaysDifference = (createdAt) => {
        const currentDate = new Date();
        const vencimentoDate = new Date(createdAt);
        vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

        // Calcula a diferença em milissegundos e converte para dias
        const timeDiff = vencimentoDate - currentDate;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        return daysDiff;
    };

    // Função para verificar se faltam menos de 60 dias (aproximadamente 2 meses)
    const isLessThanTwoMonths = (createdAt) => {
        const daysDiff = calculateDaysDifference(createdAt);
        return daysDiff <= 65; // Aproximadamente 2 meses
    };
    // Função para verificar se faltam menos de 30 dias para o vencimento

    const isLessThanOneMonth = (createdAt) => {
        const daysDiff = calculateDaysDifference(createdAt);
        return daysDiff <= 30;
    };

    // Função para verificar se faltam mais de 30 dias
    const isMoreThanOneMonth = (createdAt) => {
        const daysDiff = calculateDaysDifference(createdAt);
        return daysDiff > 30;
    };

    const calculateOneMonthBeforeVencimento = (createdAt) => {
        const vencimentoDate = new Date(createdAt);
        vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

        // Subtrai 30 dias do vencimento
        vencimentoDate.setDate(vencimentoDate.getDate() - 30);

        return vencimentoDate.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "long",
            year: "numeric",
        });
    };

    //   Fim das coisas da Gab

    useEffect(() => {
        setModalUser(user);
    }, [modalAccount, modalConfig]);

    useEffect(() => {
        if (!props.navbarOpen) setTurned(0);
    }, [props.navbarOpen]);

    useEffect(() => {
        let lastOrders = Object.values(orders).filter((e) => {
            return (
                new Date(e.createdAt) - (Date.now() - 1000 * 60 * 60 * 24 * 6) >
                    0 &&
                (e.status === 0 || e.status === 1 || e.status === 5) &&
                e.marketplace !== 82
            );
        });
        let minCred = Math.max(
            lastOrders.reduce((prev, current) => prev + current.price, 0) -
                user.credits,
            50
        );
        setMinCredits(minCred);
        modals.current.minCredits = minCred;
    }, [user, orders]);

    const fetchExtract = async () => {
        const response = await fetch("/api/users/balance", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const balances = await response.json();
        if (balances) setExtract(balances);
    };

    useEffect(() => {
        if (modalExtract) {
            fetchExtract();
        } else {
            setTimeout(() => setExtract(null), 200);
        }
    }, [modalExtract]);

    const handleCode = async () => {
        if (code?.replace(/\D/g, "")?.length < 6) {
            return alert("Preencha o código corretamente!");
        }

        setCodeLoading(true);

        const response = await fetch("/api/users/validatenewcode", {
            method: "POST",
            body: JSON.stringify({
                user: modalUser,
                code: code,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const data = await response.json();

        try {
            setCodeLoading(false);
            if (data.status) {
                setCode("");
                setModalCode(false);
                setModalAccount(false);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.log(error);
            setCodeLoading(false);
        }
    };

    const saveUser = async () => {
        // if (user?.config?.cutHourActive) {
        //     if (!user?.config?.cutHour || user?.config?.cutHour?.length < 4)
        //         return alert("Preencha o horário de corte corretamente!");
        // }
        setModalConfig(false);
        await fetch("/api/users/updateconfig", {
            method: "PUT",
            body: JSON.stringify({
                config: config,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    const saveAccount = async () => {
        if (!modalUser.name || !modalUser.phone) {
            return alert(
                "Você deve preencher seu nome e whatsapp corretamente!"
            );
        }

        if (
            !modalUser.address?.cep ||
            !modalUser.address?.localidade ||
            !modalUser.address?.uf ||
            !modalUser.address?.logradouro ||
            !modalUser.address?.numero
        ) {
            return alert("Preencha seu endereço corretamente!");
        }

        if (modalUser.address?.cep?.replace(/\D/g, "")?.length < 8) {
            return alert("CEP inválido!");
        }

        if (modalUser.phone.length < 11) {
            return alert("Whatsapp inválido!");
        }

        if (modalUser.phone !== user.phone) {
            fetch("/api/users/newcode", {
                method: "POST",
                body: JSON.stringify({
                    phone: modalUser.phone,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            return setModalCode(true);
        }

        setModalAccount(false);
        fetch("/api/users/updateuser", {
            method: "PUT",
            body: JSON.stringify({
                user: modalUser,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    const handleFileChange = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("photo", e.target.files[0]);

        fetch("/api/users/uploadphoto", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                //alert(JSON.stringify(data));
                // Atualizar as informações da foto no estado
                console.log(data.photo);
                setModalUser({
                    ...modalUser,
                    photo: "/uploads/users/" + data?.photo?.filename,
                });
                // const updatedPhotos = [
                //     ...(modalProduct.photos || []),
                //     ...data.photo.map((e) => e.filename),
                // ];
                // setModalProduct({ ...modalProduct, photos: updatedPhotos });
                // setLoadingPhoto(false);
                // e.target.value = "";
            })
            .catch((error) => {
                console.error(error);
                alert("erro ao fazer upload das fotos");
                // setLoadingPhoto(false);
                e.target.value = "";
            });
    };

    return (
        <>
            <div className={`navbar-mobile ${hidden ? "hidden" : ""}`}>
                <img
                    src="/img/navbar/burger.svg"
                    onClick={() => props.setNavbarOpen(true)}
                />
            </div>
            <div
                className={`navbar-mobile-open ${
                    props.navbarOpen ? "shown" : ""
                }`}
                onClick={() => props.setNavbarOpen(false)}
            />
            <div
                className={`navbar ${props.navbarOpen ? "open-mobile" : ""} ${
                    modalConfig ||
                    modalWallet ||
                    modalExtract ||
                    modalAccount ||
                    modalCode
                        ? "modal"
                        : ""
                } ${!user.screening ? "disabled" : ""}`}
            >
                <div
                    className={`config-modal-bg ${modalWallet ? "shown" : ""}`}
                    onClick={() => {
                        setModalWallet(0);
                        setWalletValue(null);
                    }}
                >
                    {modalWallet ? (
                        <ModalMP
                            onClick={(ev) => ev.stopPropagation()}
                            user={user}
                            walletValue={walletValue}
                            modalWallet={modalWallet}
                            setModalWallet={setModalWallet}
                            setWalletValue={setWalletValue}
                        />
                    ) : (
                        <></>
                    )}
                </div>

                <div
                    className={`config-modal-bg ${modalExtract ? "shown" : ""}`}
                    onClick={() => {
                        setModalExtract(false);
                    }}
                >
                    <div
                        className="extract-modal"
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        <div className="extract-modal-title">
                            Extrato de movimentações
                        </div>
                        <CSVLink
                            filename={`Extrato Orizom ${new Date()
                                .toISOString()
                                .slice(0, 19)
                                .replace("T", " ")}.csv`}
                            className={`extract-download-button ${
                                extract && extract.length ? "shown" : ""
                            }`}
                            separator=";"
                            data={[
                                [
                                    "Data",
                                    "Movimentação",
                                    "Saldo antes",
                                    "Saldo Após",
                                    "Motivo",
                                ],
                                ...(extract || []).map((e) => [
                                    new Date(e.createdAt)
                                        .toLocaleString()
                                        .slice(0, 17)
                                        .replace(",", ""),
                                    e.difference,
                                    e.valueBefore,
                                    e.valueAfter,
                                    e.reason,
                                ]),
                            ]}
                        >
                            {" "}
                            Excel{" "}
                        </CSVLink>
                        {extract ? "" : <Loader />}
                        {extract && !extract.length ? (
                            <div className="empty-extract">
                                <span>
                                    Nenhuma movimentação ainda! Insira saldo
                                    para iniciar sua jornada!
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div
                            className={`extract-container ${
                                extract && extract.length ? "shown" : ""
                            }`}
                        >
                            {(extract || []).map((item) => {
                                return (
                                    <div className="extract-item">
                                        <div className="extract-date">
                                            {
                                                new Date(item.createdAt)
                                                    .toLocaleString()
                                                    .slice(0, 17)
                                                    .replace(",", "")
                                                // .replace(/-/g, "/")
                                                // .replace("T", " ")
                                            }
                                        </div>
                                        <div className="extract-reason">
                                            {item.reason}
                                        </div>
                                        <div
                                            className={`extract-movimentation ${
                                                item.difference > 0
                                                    ? "add"
                                                    : "subtract"
                                            }`}
                                        >
                                            <small>
                                                {item.difference < 0
                                                    ? "-"
                                                    : "+"}
                                                R$
                                            </small>
                                            {Math.round(
                                                Math.abs(item.difference)
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div
                    className={`config-modal-bg ${modalAccount ? "shown" : ""}`}
                    onClick={() => setModalAccount(false)}
                >
                    <div
                        className={`config-modal config-modal-account`}
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        <div className="cut-hour">Minha conta</div>
                        <label className="account-photo">
                            <img
                                src={
                                    modalUser.photo
                                        ? modalUser.photo
                                        : "/img/default-icon.svg"
                                }
                            />
                            <input
                                className="add-photo-btn"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </label>
                        <label className="account-label">
                            Nome completo
                            <input
                                className="input-account-name"
                                value={modalUser.name}
                                onChange={(ev) => {
                                    setModalUser({
                                        ...modalUser,
                                        name: ev.target.value,
                                    });
                                }}
                            />
                        </label>
                        <label className="account-label">
                            E-mail
                            <input value={modalUser.email} disabled />
                        </label>
                        {/* <div className="account-label-address">
                            <label className="account-label account-label-fit">
                                {modalUser.doc?.length > 11 ? "CNPJ" : "CPF"}
                                <ReactInputMask
                                    mask={
                                        modalUser?.doc?.length < 12
                                            ? "999.999.999-999"
                                            : "99.999.999/9999-99"
                                    }
                                    maskChar={""}
                                    value={modalUser?.doc}
                                    placeholder="CPF / CNPJ"
                                    // onChange={(ev) => {
                                    //     // alert(ev.target.value.replace(/\D/g, ""));
                                    //     setDoc(ev.target.value.replace(/\D/g, ""));
                                    // }}
                                    disabled
                                />
                                <div className="conf-information-container">
                                    <div className="conf-information conf-information-user">
                                        ?
                                    </div>
                                    <div className="conf-information-bubble conf-bubble-user">
                                        Caso seja necessário realizar alteração,
                                        entre em contato com a equipe da Orizom.
                                    </div>
                                </div>
                            </label>
                            {modalUser.doc?.length > 11 ? (
                                <div
                                    className="faturador-btn"
                                    onClick={() => setFaturadorPopup(true)}
                                >
                                    <img src="/img/config-icon.svg" />
                                    Faturador
                                </div>
                            ) : (
                                <></>
                            )}
                        </div> */}
                        <label className="account-label">
                            Whatsapp
                            <ReactInputMask
                                mask="(99) 99999-9999"
                                value={modalUser.phone}
                                onChange={(ev) => {
                                    setModalUser({
                                        ...modalUser,
                                        phone: ev.target.value.replace(
                                            /\D/g,
                                            ""
                                        ),
                                    });
                                }}
                            />
                        </label>
                        <div className="account-label-address">
                            <label className={`account-label account-cep`}>
                                CEP
                                <ReactInputMask
                                    mask="99999-999"
                                    value={modalUser.address?.cep}
                                    onChange={(ev) => {
                                        setModalUser({
                                            ...modalUser,
                                            address: {
                                                ...(modalUser.address || {}),
                                                cep: ev.target.value,
                                            },
                                        });
                                    }}
                                />
                            </label>
                            <label className="account-label">
                                Cidade
                                <input
                                    value={modalUser.address?.localidade}
                                    onChange={(ev) => {
                                        setModalUser({
                                            ...modalUser,
                                            address: {
                                                ...(modalUser.address || {}),
                                                localidade: ev.target.value,
                                            },
                                        });
                                    }}
                                />
                            </label>
                            <label className={`account-label account-uf`}>
                                UF
                                <ReactInputMask
                                    mask="aa"
                                    value={modalUser.address?.uf}
                                    onChange={(ev) => {
                                        setModalUser({
                                            ...modalUser,
                                            address: {
                                                ...(modalUser.address || {}),
                                                uf: ev.target.value,
                                            },
                                        });
                                    }}
                                />
                            </label>
                        </div>
                        <div className="account-label-address">
                            <label className={`account-label`}>
                                Rua
                                <input
                                    value={modalUser.address?.logradouro}
                                    onChange={(ev) => {
                                        setModalUser({
                                            ...modalUser,
                                            address: {
                                                ...(modalUser.address || {}),
                                                logradouro: ev.target.value,
                                            },
                                        });
                                    }}
                                />
                            </label>
                            <label
                                className={`account-label account-address-number`}
                            >
                                Número
                                <input
                                    value={modalUser.address?.numero}
                                    onChange={(ev) => {
                                        setModalUser({
                                            ...modalUser,
                                            address: {
                                                ...(modalUser.address || {}),
                                                numero: ev.target.value,
                                            },
                                        });
                                    }}
                                />
                            </label>
                        </div>
                        <div className="config-save-btn" onClick={saveAccount}>
                            Salvar
                        </div>
                        <div
                            className={`faturador-popup ${
                                faturadorPopup ? "shown" : ""
                            }`}
                        >
                            <div
                                className="faturador-back"
                                onClick={() => setFaturadorPopup(false)}
                            >
                                Voltar
                            </div>
                            {user?.cert ? (
                                <div className="cert-box">
                                    Seu certificado A1 está vinculado com
                                    sucesso em nosso sistema. O vencimento de
                                    seu certificado é{" "}
                                    <strong>{user?.cert?.expiration}</strong>.
                                    <br />
                                    Para atualizar seu certificado, basta
                                    inserir o arquivo e senha dele abaixo.
                                </div>
                            ) : (
                                <div className="cert-box none">
                                    Nenhum certificado configurado ainda. Para
                                    configurar, insira o arquivo PFX e a senha
                                    de seu certificado abaixo.
                                </div>
                            )}
                            <label class="custom-file-upload">
                                Selecionar arquivo (.pfx)
                                <input
                                    type="file"
                                    accept=".pfx"
                                    onChange={(ev) => {
                                        setCert(ev?.target?.files[0] || null);
                                    }}
                                />
                            </label>
                            {cert ? (
                                <div className="cert-selected">
                                    Selecionado: <strong>{cert?.name}</strong>
                                </div>
                            ) : (
                                <></>
                            )}
                            <label className="account-label">
                                Senha do certificado
                                <input
                                    id="cert-password"
                                    type="password"
                                    value={certPassword}
                                    onChange={(ev) => {
                                        setCertPassword(ev.target.value);
                                    }}
                                    autoComplete="off"
                                />
                            </label>
                            <div
                                className={`config-save-btn cert-save ${
                                    cert && certPassword?.length > 3
                                        ? "enabled"
                                        : ""
                                }`}
                                onClick={() => {
                                    const formData = new FormData();
                                    formData.append("cert", cert);
                                    formData.append("password", certPassword);

                                    setCertLoading(true);

                                    fetch("/api/users/uploadcert", {
                                        method: "POST",
                                        body: formData,
                                    })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            //alert(JSON.stringify(data));
                                            // Atualizar as informações da foto no estado

                                            setCertLoading(false);

                                            if (data.error) {
                                                return Popup.fire({
                                                    title: "Erro",
                                                    text: data.error,
                                                    icon: "error",
                                                });
                                            }

                                            setCert(null);
                                            setCertPassword("");

                                            console.log(data);

                                            return Popup.fire({
                                                title: "Sucesso",
                                                text: "Seu certificado foi vinculado com sucesso!",
                                                icon: "success",
                                            });
                                            // const updatedPhotos = [
                                            //     ...(modalProduct.photos || []),
                                            //     ...data.photo.map((e) => e.filename),
                                            // ];
                                            // setModalProduct({ ...modalProduct, photos: updatedPhotos });
                                            // setLoadingPhoto(false);
                                            // e.target.value = "";
                                        })
                                        .catch((error) => {
                                            setCertLoading(false);
                                            console.error(error);
                                            setCert("");
                                            return Popup.fire({
                                                title: "Oops...",
                                                text: "Erro de conexão. Verifique sua conexão ou tente novamente mais tarde.",
                                                icon: "error",
                                            });
                                            // setLoadingPhoto(false);
                                        });
                                }}
                            >
                                {user?.cert ? "Atualizar" : "Adicionar"}{" "}
                                certificado
                            </div>
                            <div
                                className={`cert-loader ${
                                    certLoading ? "shown" : ""
                                }`}
                            >
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`config-modal-bg ${modalCode ? "shown" : ""}`}
                    onClick={() => {
                        setModalCode(false);
                    }}
                >
                    <div
                        className={`config-modal config-modal-code`}
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        <div
                            className={`modal-code-loading ${
                                codeLoading ? "shown" : ""
                            }`}
                        >
                            <Loader />
                        </div>
                        Digite o código de verificação que enviamos para seu
                        WhatsApp:
                        <ReactInputMask
                            className="code-input"
                            mask="9 9 9 9 9 9"
                            value={code}
                            onChange={(ev) => setCode(ev.target.value)}
                        />
                        <div
                            className="code-verify-btn"
                            onClick={() => handleCode()}
                        >
                            Verificar
                        </div>
                    </div>
                </div>

                <div
                    className={`config-modal-bg ${modalConfig ? "shown" : ""}`}
                    onClick={() => setModalConfig(false)}
                >
                    <div
                        className="config-modal"
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        <div className="config-title">
                            <Einstein start={true} /> Configurações AI-stem
                        </div>
                        {Object.entries(userConfigs).map(
                            ([confId, conf], i) => {
                                let indent = 0;
                                if (conf.dependsOn) {
                                    if (
                                        userConfigs[conf.dependsOn]?.dependsOn
                                    ) {
                                        indent = 2;
                                    } else indent = 1;
                                }
                                switch (conf.type) {
                                    case "slider":
                                        return (
                                            <div
                                                style={{ zIndex: 1000 - i }}
                                                className={`config-slider`}
                                            >
                                                <div className="config-name">
                                                    {conf.name}{" "}
                                                    <div className="config-value">
                                                        {config?.[confId] ||
                                                            100}
                                                        %
                                                    </div>
                                                </div>
                                                <div class="config-slidecontainer">
                                                    <input
                                                        style={{
                                                            background: `linear-gradient(to right, #1567d2 0%, #1567d2 ${
                                                                ((config?.[
                                                                    confId
                                                                ] || 100) /
                                                                    conf
                                                                        .range[1]) *
                                                                100
                                                            }%, #1d1d1d ${
                                                                ((config?.[
                                                                    confId
                                                                ] || 100) /
                                                                    conf
                                                                        .range[1]) *
                                                                100
                                                            }%, #1d1d1d 100%)`,
                                                        }}
                                                        type="range"
                                                        min={conf.range[0]}
                                                        max={conf.range[1]}
                                                        value={
                                                            config?.[confId] ||
                                                            100
                                                        }
                                                        className={`config-slider-input ${
                                                            config?.[confId] ===
                                                            "0"
                                                                ? "zerovolume"
                                                                : ""
                                                        }`}
                                                        onChange={(ev) => {
                                                            setConfig({
                                                                ...(config ||
                                                                    {}),
                                                                [confId]:
                                                                    ev.target
                                                                        .value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );

                                    case "bool":
                                    default:
                                        return (
                                            <div
                                                className={`config-bool ${
                                                    conf.dependsOn &&
                                                    (!config?.[
                                                        conf.dependsOn
                                                    ] ||
                                                        (userConfigs[
                                                            conf.dependsOn
                                                        ].dependsOn &&
                                                            !config?.[
                                                                userConfigs[
                                                                    conf
                                                                        .dependsOn
                                                                ].dependsOn
                                                            ]))
                                                        ? "disabled"
                                                        : ""
                                                }`}
                                            >
                                                <div className="config-name">
                                                    {conf.name}
                                                    <div className="conf-information-container">
                                                        <div className="conf-information">
                                                            ?
                                                        </div>
                                                        <div className="conf-information-bubble">
                                                            {conf.text}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`switch ${
                                                        config?.[confId]
                                                            ? "checked"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        setConfig({
                                                            ...(config || {}),
                                                            [confId]:
                                                                !config?.[
                                                                    confId
                                                                ],
                                                        });
                                                    }}
                                                >
                                                    <span className="slider" />
                                                </div>
                                            </div>
                                        );
                                }
                            }
                        )}
                        {/* <div className="cut-hour">
                            Horário de corte
                            <div
                                className={`switch ${user?.config?.cutHourActive ? "checked" : ""}`}
                                onClick={() => {
                                    setUser({
                                        ...user,
                                        config: {
                                            ...user?.config,
                                            cutHourActive: !user?.config?.cutHourActive,
                                        },
                                    });
                                }}
                            >
                                <span className="slider" />
                            </div>
                        </div>
                        <div className={`cut-hour-input ${user?.config?.cutHourActive ? "shown" : ""}`}>
                            <input
                                value={
                                    (user?.config?.cutHour || "").slice(0, 2).padEnd(2, "_") +
                                    ":" +
                                    (user?.config?.cutHour || "").slice(2, 4).padEnd(2, "_")
                                }
                                onChange={(ev) => {
                                    let value = ev.target.value.replace(/\D/g, "");
                                    if (ev.target.value.length < 5) value = user?.config?.cutHour?.slice(0, -1);

                                    if (value.slice(0, 2) > 23) value = `23${value.slice(2, 4)}`;
                                    if (value.slice(2, 4) > 59) value = `${value.slice(0, 2)}59`;
                                    setUser({
                                        ...user,
                                        config: {
                                            ...user?.config,
                                            cutHour: value.slice(0, 4),
                                        },
                                    });
                                }}
                                placeholder="00:00"
                            />
                        </div>
                        <div className="cut-hour-explanation">
                            Se seus envios são levados até o Posto de Coleta do Mercado Livre, insira o horário de corte! O não
                            cadastramento do seu horário de corte resultará em atrasos!
                        </div> */}
                        <div className="config-save-btn" onClick={saveUser}>
                            Salvar
                        </div>
                    </div>
                </div>
                <img
                    className="drop-logo"
                    src="/img/logo-orizom.svg"
                    alt="Logo da Orizom"
                />
                <div
                    className={`wallet-container ${
                        isTurned == 1
                            ? "turned-1"
                            : isTurned == 2
                            ? "turned-2"
                            : ""
                    }`}
                >
                    <div className="wallet-credits-explanation">
                        {minCredits <= 51
                            ? `Você já possui capital o suficiente para garantir suas
                    vendas pelos próximos 7 dias! Se quiser, adicione para
                    fortalecer ainda mais seu capital de giro!`
                            : "Você não tem capital de giro o suficiente para garantir suas vendas pelos próximos 7 dias! Insira mais saldo para evitar atrasos em suas entregas."}
                        <br />
                        <br />
                        <strong>Mínimo R$ {minCredits}</strong>
                    </div>
                    <div className="wallet">
                        <div className="wallet-1">
                            <div className="wallet-more">
                                <div
                                    className="wallet-more-text"
                                    onClick={() => {
                                        setModalExtract(true);
                                    }}
                                >
                                    Ver extrato
                                </div>
                            </div>
                            <p>Sua carteira</p>
                            <div className="wallet-value">
                                R${" "}
                                <span className="wallet-value-number">
                                    <SlotNumber
                                        number={user.credits}
                                        height={28}
                                        delay={1000}
                                    />
                                </span>
                            </div>
                            <div
                                className="add-credits-btn"
                                onClick={() => setTurned(1)}
                            >
                                Inserir saldo
                            </div>
                        </div>
                        <div className="wallet-2">
                            <div className="credits-insert-value-container">
                                <input
                                    className="credits-insert-value"
                                    value={walletValue || ""}
                                    onChange={(ev) => {
                                        setWalletValue(
                                            ev.target.value
                                                .slice(0, 5)
                                                .replace(/\D/g, "")
                                        );
                                    }}
                                />
                            </div>
                            <div
                                className="add-credits-btn"
                                onClick={() => {
                                    if (
                                        (!walletValue ||
                                            walletValue < minCredits) &&
                                        user?.id !== 117
                                    )
                                        return alert(
                                            `O mínimo de créditos é R$ ${minCredits}!`
                                        );
                                    setTurned(0);
                                    setModalWallet(1);
                                }}
                            >
                                Pagamento
                            </div>
                            <div
                                className="add-credits-back"
                                onClick={() => setTurned(0)}
                            >
                                Voltar
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="lateral-menu">
                    {/*<li><Link to='/feed' className={location.pathname === '/feed' ? 'current-page' : ''}>Feed</Link></li>*/}
                    {/* <li><Link to='/dashboard' className={location.pathname === '/dashboard' || location.pathname == '/' ? 'current-page' : '' ? 'current-page' : ''}>Dashboard</Link></li> */}

                    <li>
                        <Link
                            to="/produtos"
                            href="javascript:void(0)"
                            className={
                                location.pathname === "/" ||
                                location.pathname === "/produtos"
                                    ? "current-page"
                                    : ""
                            }
                            onClick={() => props.setNavbarOpen(false)}
                        >
                            Produtos
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/pedidos"
                            className={
                                location.pathname === "/pedidos"
                                    ? "current-page"
                                    : ""
                            }
                            onClick={() => props.setNavbarOpen(false)}
                        >
                            Pedidos
                        </Link>
                    </li>
                    {/* <li>Atendimento</li> */}
                    <li>
                        <Link
                            to="/integracoes"
                            className={
                                location.pathname === "/integracoes"
                                    ? "current-page"
                                    : ""
                            }
                            onClick={() => props.setNavbarOpen(false)}
                        >
                            Integrações
                            {Object.values(integrations).length ? (
                                <span className="lateral-arrow"></span>
                            ) : (
                                ""
                            )}
                        </Link>
                        {Object.values(integrations)?.find(
                            (e) => e.type === 0
                        ) ? (
                            <Link
                                to="/integracoes/bling"
                                className={`${
                                    location.pathname === "/integracoes/bling"
                                        ? "current-page"
                                        : ""
                                } lateral-sub`}
                                onClick={() => props.setNavbarOpen(false)}
                            >
                                Bling
                            </Link>
                        ) : (
                            ""
                        )}
                        {Object.values(integrations)?.find(
                            (e) => e.type === 1
                        ) ? (
                            <Link
                                to="/integracoes/meli"
                                className={`${
                                    location.pathname === "/integracoes/meli"
                                        ? "current-page"
                                        : ""
                                } lateral-sub`}
                                onClick={() => props.setNavbarOpen(false)}
                            >
                                Mercado Livre
                            </Link>
                        ) : (
                            ""
                        )}
                        {Object.values(integrations)?.find(
                            (e) => e.type === 2
                        ) ? (
                            <Link
                                to="/integracoes/magalu"
                                className={`${
                                    location.pathname === "/integracoes/magalu"
                                        ? "current-page"
                                        : ""
                                } lateral-sub`}
                                onClick={() => props.setNavbarOpen(false)}
                            >
                                Magalu
                            </Link>
                        ) : (
                            ""
                        )}
                    </li>
                </ul>
                <div className="navbar-user-container">
                    {/* Gab mudanças */}
                    {isLessThanTwoMonths(user.lastPaid || user.createdAt) ? (
                        <div className="franquia-container">
                            <h3>Renovação da franquia</h3>
                            <p>
                                Vencimento:{" "}
                                {calculateVencimentoAbreviado(
                                    user.lastPaid || user.createdAt
                                )}
                            </p>
                            <p>
                                Valor: {calculateRenewalValue(user.createdAt)}
                            </p>
                            <button
                                className="ver-detalhes-btn"
                                onClick={() => setShowPopup(true)}
                            >
                                Ver detalhes
                            </button>

                            <div
                                className={`config-modal-bg ${
                                    showPopup ? "shown" : ""
                                }`}
                                onClick={() => {
                                    setShowPopup(false);
                                }}
                            >
                                <div
                                    className="extract-modal popup-franquia"
                                    onClick={(ev) => ev.stopPropagation()}
                                >
                                    <div className="popup-content">
                                        <h2>RENOVAÇÃO DA FRANQUIA</h2>
                                        <p>
                                            No dia{" "}
                                            <strong>
                                                {calculateVencimento(
                                                    user.lastPaid ||
                                                        user.createdAt
                                                )}
                                            </strong>{" "}
                                            vence o seu contrato da franquia
                                            Orizom, correspondente aos 365 dias
                                            (1 ano). Para renovar o seu
                                            contrato, realize o pagamento até a
                                            data do vencimento.
                                        </p>

                                        {isMoreThanOneMonth(
                                            user.lastPaid || user.createdAt
                                        ) && (
                                            <>
                                                <p>
                                                    <strong>
                                                        Realizando o pagamento
                                                        até o dia{" "}
                                                        {calculateOneMonthBeforeVencimento(
                                                            user.lastPaid ||
                                                                user.createdAt
                                                        )}
                                                        , depositaremos um bônus
                                                        de R$400,00 de saldo em
                                                        sua carteira virtual!
                                                    </strong>
                                                </p>

                                                <a
                                                    href={`${
                                                        window.location.protocol
                                                    }//${
                                                        window.location
                                                            .hostname ==
                                                        "localhost"
                                                            ? `${window.location.hostname}:5000`
                                                            : window.location
                                                                  .hostname
                                                    }/api/mercadopago/renewlink`}
                                                    target="_blank"
                                                    className="antecipar-btn"
                                                >
                                                    Antecipar Renovação
                                                </a>
                                            </>
                                        )}
                                        {isLessThanOneMonth(
                                            user.lastPaid || user.createdAt
                                        ) && (
                                            <a
                                                href={`${
                                                    window.location.protocol
                                                }//${
                                                    window.location.hostname ==
                                                    "localhost"
                                                        ? `${window.location.hostname}:5000`
                                                        : window.location
                                                              .hostname
                                                }/api/mercadopago/renewlink`}
                                                target="_blank"
                                                className="renovar-btn"
                                            >
                                                Renovar Franquia
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* Fim Gab mudanças */}
                    <div
                        className="navbar-user"
                        onClick={() => {
                            setModalUser({ ...user });
                            setModalAccount(true);
                        }}
                    >
                        <img
                            src={
                                user.photo
                                    ? user.photo
                                    : "/img/default-icon.svg"
                            }
                            className="navbar-user-img"
                        />
                        <div className="navbar-username-container">
                            <div className="navbar-username">
                                {user.name.split(" ")[0]}
                            </div>
                            <div className="navbar-account-type">
                                Minha conta
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-config">
                        {/*<li>
                    <a
                        href="#"
                        onClick={() => {
                            setModalUser({ ...user });
                            setModalAccount(true);
                        }}
                    >
                        <img
                            className="config-icon"
                            src="/img/config-icon.svg"
                        />
                        Minha conta
                    </a>
                    </li>*/}
                        <li>
                            <a
                                href="#"
                                onClick={() => {
                                    setModalConfig(true);
                                }}
                            >
                                <img
                                    className="config-icon"
                                    src="/img/config-icon.svg"
                                />
                                Configurações
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                onClick={() => {
                                    Cookies.remove("jwt");
                                    window.location.replace("/");
                                }}
                            >
                                <img
                                    className="config-icon"
                                    src="/img/logout-icon.svg"
                                />
                                Sair
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

const ModalMP = (props) => {
    const { payments } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);

    const [walletData, setWalletData] = useState({});
    const [expireDate, setExpireDate] = useState({});

    const [payment, setPayment] = useState({});

    useEffect(() => {
        console.log(payments);
        console.log(payment.preference);
        console.log("-------------------------------------");
        if (
            payments.find((e) => e.externalReference == payment?.preference?.id)
        ) {
            props.setModalWallet(0);
            props.setWalletValue(null);
        }
    }, [payments, payment]);

    initMercadoPago(mercadoPagoKey, {
        locale: "pt-BR",
    });

    useEffect(() => {
        if (props.modalWallet === 2 && Object.values(payment)?.length) {
            setPix();
        }
    }, [props.modalWallet, payment]);

    const setPix = async () => {
        try {
            const response = await fetch("/api/mercadopago/process_payment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    paymentData: {
                        payment_method_id: "pix",
                        transaction_amount: props.walletValue,
                        payer: {
                            email: props.user.email,
                        },
                    },
                    userId: props.user.id,
                    externalReference: payment.preference.id,
                }),
            });
            const responseData = await response.json();
            setWalletData(responseData);

            if (
                !(
                    responseData.payment_method_id &&
                    responseData?.payment_method_id !== "pix" &&
                    responseData?.payment_method_id !== "wallet_purchase"
                )
            )
                setReady(true);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(
                "Verifique se todos os dados estão corretos e tente novamente!"
            );
        }
    };

    const customization = {
        visual: {
            hideFormTitle: true,
            hideRedirectionPanel: true,
            hidePaymentButton: true,
            style: {
                theme: "dark",
                customVariables: {
                    textPrimaryColor: "#fff",
                    formBackgroundColor: "#090909",
                    baseColor: "#1567D2",
                    baseColorSecondVariant: "#1A1A1A",
                    buttonTextColor: "#fff",
                    fontSizeLarge: "15px",
                    fontSizeMedium: "12px",
                    fontSizeExtraSmall: "10px",
                    borderRadiusSmall: "6px",
                    borderRadiusMedium: "8px",
                    borderRadiusLarge: "16px",
                    borderRadiusFull: "50%",
                    formPadding: "10px",
                },
            },
            texts: {
                paymentMethods: {
                    creditCardValueProp: "Parcelado em até 12x",
                    // creditCardValueProp: "Desconto de 4% em suas compras",
                    bankTransferValueProp: `Ganhe R$ ${Math.ceil(
                        props.walletValue * 0.04
                    )} de bônus`,
                },
            },
        },
        paymentMethods: {
            creditCard: "all",
            mercadoPago: ["wallet_purchase"],
            bankTransfer: ["pix"],
        },
    };

    const createPayment = () => {
        window.paymentBrickController
            .getFormData()
            .then(async (data) => {
                console.log(data);
                if (!data.formData && data.paymentType !== "wallet_purchase")
                    return;
                setLoading(true);
                if (data.paymentType === "wallet_purchase") {
                    setWalletData({
                        ...(data?.formData || {}),
                        payment_method_id: "wallet_purchase",
                    });
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                } else {
                    try {
                        const response = await fetch(
                            "/api/mercadopago/process_payment",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    paymentData: data.formData,
                                    userId: props.user.id,
                                    externalReference: payment.preference.id,
                                }),
                            }
                        );
                        const responseData = await response.json();
                        setWalletData(responseData);

                        if (
                            !(
                                responseData.payment_method_id &&
                                responseData?.payment_method_id !== "pix" &&
                                responseData?.payment_method_id !==
                                    "wallet_purchase"
                            )
                        )
                            setLoading(false);
                    } catch (err) {
                        setLoading(false);
                        alert(
                            "Verifique se todos os dados estão corretos e tente novamente!"
                        );
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
    };
    const onReady = async () => {
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
    };

    useEffect(() => {
        if (!Object.values(payment).length) {
            (async () => {
                const response = await fetch(
                    "/api/mercadopago/create_preference",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            type: "credits",
                            value: props.walletValue,
                        }),
                    }
                );
                const responseData = await response.json();
                setPayment(responseData);

                console.log(
                    responseData.customer.cards.map((card) => card?.id)
                );
            })();
        }
    }, []);

    let minuteInterval;
    useEffect(() => {
        minuteInterval = setInterval(() => {
            if (!walletData?.date_of_expiration) return;
            let minutes = Math.floor(
                (new Date(walletData?.date_of_expiration) - Date.now()) /
                    1000 /
                    60
            );
            let seconds = Math.floor(
                (new Date(walletData?.date_of_expiration) -
                    Date.now() -
                    minutes * 60 * 1000) /
                    1000
            );
            setExpireDate({
                minutes: minutes,
                seconds: seconds,
                expired: seconds <= 0 && minutes <= 0,
            });

            if (seconds <= 0 && minutes <= 0) props.setModalWallet(0);
        }, 1000);
        return () => clearInterval(minuteInterval);
    }, [walletData]);

    const ref1 = createRef();

    return (
        <div className="modal-mp" key="1" onClick={props.onClick} ref={ref1}>
            <div className={`modal-mp-loading ${loading ? "shown" : ""}`}>
                <Loader />
            </div>
            <div className="modal-mp-content">
                <div className="modal-mp-header">
                    Comprando{" "}
                    <span className="mp-credits-value">
                        R${" "}
                        {ready ? (
                            <SlotNumber
                                className="mp-header-value"
                                number={props.walletValue}
                                height={20}
                                startDelay={200}
                            />
                        ) : (
                            <></>
                        )}{" "}
                    </span>
                    em créditos
                </div>
                <div className="wallet-status">
                    {React.useMemo(() => {
                        return walletData.payment_method_id &&
                            walletData?.payment_method_id !== "pix" &&
                            walletData?.payment_method_id !==
                                "wallet_purchase" ? (
                            <StatusScreen
                                initialization={{
                                    paymentId: walletData.id,
                                }}
                                customization={customization}
                                onReady={() => setLoading(false)}
                            />
                        ) : (
                            <></>
                        );
                    }, [walletData])}
                </div>
                <div
                    className={`wallet-pix ${
                        walletData?.payment_method_id === "pix" ? "shown" : ""
                    }`}
                >
                    <QRCode
                        className="wallet-qr-image"
                        value={
                            walletData?.point_of_interaction?.transaction_data
                                ?.qr_code
                        }
                        size={220}
                        eyeRadius={3}
                        logoImage="/img/qrcode/logo.png"
                        logoHeight={60}
                        logoWidth={60}
                        // removeQrCodeBehindLogo={true}
                        logoPadding={0}
                    />
                    <div className="wallet-pix-data">
                        <CopyDiv
                            className="pix-copy-paste-container"
                            value={
                                walletData?.point_of_interaction
                                    ?.transaction_data?.qr_code
                            }
                        >
                            Copiar código PIX
                        </CopyDiv>
                        <div className="wallet-pix-expire-container">
                            Expira em{" "}
                            {walletData?.date_of_expiration ? (
                                <span className="wallet-pix-expire">
                                    <SlotNumber
                                        height={18}
                                        number={
                                            expireDate.minutes
                                                ?.toString()
                                                .padStart(2, "0") || "00"
                                        }
                                    />
                                    :
                                    <SlotNumber
                                        height={18}
                                        number={
                                            expireDate.seconds
                                                ?.toString()
                                                .padStart(2, "0") || "00"
                                        }
                                    />
                                </span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`wallet-pix ${
                        walletData?.payment_method_id === "wallet_purchase"
                            ? "shown"
                            : ""
                    }`}
                >
                    <div className="wallet-mercadopago-data">
                        Continue sua compra no site do Mercado Pago e seu saldo
                        será atualizado automaticamente!
                    </div>
                    <div
                        className="wallet-mercadopago-btn"
                        onClick={() => {
                            props.setModalWallet(0);
                        }}
                    >
                        Fechar
                    </div>
                </div>
                <div
                    className={`wallet-payment-select ${
                        walletData?.payment_method_id || props.modalWallet === 2
                            ? ""
                            : "shown"
                    }`}
                >
                    {React.useMemo(
                        () =>
                            props.modalWallet !== 2 &&
                            payment?.preference?.id ? (
                                <Payment
                                    initialization={{
                                        amount: props.walletValue,
                                        preferenceId: payment.preference.id,
                                        payer: {
                                            email: props.user.email,
                                            customerId: payment.customer.id,
                                            cardsIds:
                                                payment.customer.cards.map(
                                                    (card) => card.id
                                                ),
                                        },
                                    }}
                                    customization={customization}
                                    onReady={() => {
                                        setReady(true);
                                        setLoading(false);
                                        let all = Array.from(
                                            ref1.current?.getElementsByTagName(
                                                "div"
                                            )
                                        );
                                        let mpText = all.find((e) =>
                                            e.innerHTML.startsWith(
                                                "Pague com segurança"
                                            )
                                        );
                                        if (mpText) {
                                            let mpValue =
                                                mpText?.getElementsByTagName(
                                                    "span"
                                                )[0];
                                            mpValue.innerHTML =
                                                "Pagamento direto no Mercado Pago";
                                            mpText.innerHTML = `Mercado Pago ${mpValue.outerHTML}`;
                                        }
                                    }}
                                    onError={onError}
                                />
                            ) : (
                                <></>
                            ),
                        [payment]
                    )}
                    <div
                        className="modal-mp-payment-btn"
                        onClick={() => {
                            createPayment();
                        }}
                    >
                        Pagar
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
