import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";

import Cookies from "js-cookie";

import useWebSocket from "react-use-websocket";
import { useContext } from "react";
import { UserContext } from "../App.js";

import Queue from "sync-queue";

var queue = new Queue();

const Auth = (props) => {
    const pdfjsLib = window["pdfjs-dist/build/pdf"];

    const {
        setUser,
        setAdminProducts,
        adminProducts,
        setAdminBoxes,
        setAdminGroups,
        adminGroups,
        setAdminMaterials,
        setAdminUsers,
        adminUsers,
        setAdminOrders,
        adminOrders,
        setAdminPayments,
        setOnlineUsers,
        setMessages,
        setNewUser,
        plpPrinter,
        zebraPrint,
        autoPrint,
        setNotificationList,
        setStarboxSlots,
        setStarboxTasks,
        setStarboxStatus,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const ordersRef = useRef();

    const printed = useRef([]);

    useEffect(() => {
        ordersRef.current = adminOrders || [];
    }, [adminOrders]);

    useWebSocket(
        window.location.protocol === "http:"
            ? `ws://${window.location.hostname}:8080/wsadmin`
            : `wss://${window.location.hostname}/wsadmin`,
        {
            reconnectAttempts: 1000,
            onMessage: (msg) => {
                try {
                    let data = JSON.parse(msg.data);

                    console.log(data);

                    if (data.notifications) {
                        setNotificationList((notList) => [
                            ...notList.filter((nl) =>
                                data.notifications
                                    .map((n) => n.text)
                                    .includes(nl.text)
                            ),
                            ...(data.notifications.filter(
                                (n) =>
                                    !notList.map((a) => a.text).includes(n.text)
                            ) || []),
                        ]);
                    }

                    if (data.user) setUser(data.user);

                    if (data.users) setAdminUsers(data.users);

                    if (data.adminUser) {
                        let newUsers = [...adminUsers];
                        if (
                            adminUsers.find((u) => u.id === data.adminUser.id)
                        ) {
                            newUsers[
                                adminUsers.findIndex(
                                    (e) => e.id === data.adminUser.id
                                )
                            ] = data.adminUser;
                        } else {
                            newUsers.unshift(data.adminUser);
                        }
                        setAdminUsers(newUsers);
                    }

                    if (data.boxes) setAdminBoxes(data.boxes);

                    if (data.materials) setAdminMaterials(data.materials);

                    if (data.groups) setAdminGroups(data.groups);
                    if (data.group) {
                        if (data.group.destroy) {
                            setAdminGroups([
                                ...adminGroups.filter(
                                    (e) => e.id !== data.product.id
                                ),
                            ]);
                        } else {
                            setAdminGroups([
                                data.group,
                                ...adminGroups.filter(
                                    (e) => e.id !== data.group.id
                                ),
                            ]);
                        }
                    }

                    if (data.products) setAdminProducts(data.products);
                    if (data.product) {
                        if (data.product.destroy) {
                            setAdminProducts([
                                ...adminProducts.filter(
                                    (e) => e.id !== data.product.id
                                ),
                            ]);
                        } else {
                            let newProducts = [...adminProducts];
                            let index = newProducts.findIndex(
                                (o) => o.id === data.product.id
                            );
                            if (index === -1) {
                                setAdminProducts([
                                    data.product,
                                    ...adminProducts.filter(
                                        (e) => e.id !== data.product.id
                                    ),
                                ]);
                            } else {
                                newProducts[index] = data.product;
                                setAdminProducts(newProducts);
                            }

                            // setAdminProducts([data.product, ...adminProducts.filter((e) => e.id !== data.product.id)]);
                        }
                    }

                    if (data.orders) setAdminOrders(data.orders);
                    if (data.order) {
                        // if (ordersRef.current?.length)
                        queue.place(async () => {
                            if (data.order.destroy) {
                                await setAdminOrders([
                                    ...ordersRef?.current?.filter(
                                        (e) => e.id !== data.order.id
                                    ),
                                ]);
                            } else {
                                if (
                                    data.order.status === 0 &&
                                    !data.order.nfePrinted &&
                                    !data.order.plpPrinted &&
                                    data.order.autoPrint &&
                                    autoPrint &&
                                    Cookies.get("starbox") !== "false" &&
                                    !printed.current.includes(data.order.id)
                                ) {
                                    printed.current.push(data.order.id);
                                    await printPLPs([data.order]);
                                    await printNFes([data.order]);
                                }

                                let newOrders = [...ordersRef.current];
                                let index = newOrders.findIndex(
                                    (o) => o.id === data.order.id
                                );
                                if (index === -1) {
                                    await setAdminOrders([
                                        data.order,
                                        ...ordersRef.current.filter(
                                            (e) => e.id !== data.order.id
                                        ),
                                    ]);
                                } else {
                                    newOrders[index] = data.order;
                                    await setAdminOrders(newOrders);
                                }
                            }
                            setTimeout(() => queue.next(), 100);
                        });
                    }

                    if (data.payments) {
                        setAdminPayments(data.payments);
                    }

                    if (data.messages) {
                        setMessages(data.messages);
                    }

                    if (data.message) {
                        setMessages((messages) => [data.messages, ...messages]);
                    }

                    if (data.onlineUsers) {
                        let online = [...data.onlineUsers];
                        online.reverse();
                        setOnlineUsers(online);
                    }

                    if (data.newUser) {
                        setNewUser(data.newUser);
                    }

                    if (data.starboxSlots) {
                        setStarboxSlots((slots) => data.starboxSlots);
                    }

                    if (data.starboxTasks) {
                        setStarboxTasks(data.starboxTasks);
                    }

                    if (data.starboxData) {
                        setStarboxStatus((status) => {
                            return {
                                lastUpdate: Date.now(),
                                x: data.starboxData?.x || status.x,
                                y: data.starboxData?.y || status.y,
                                z: data.starboxData?.z || status.z,
                                status:
                                    data.starboxData?.status || status.status,
                            };
                        });
                    }

                    // setAdminProducts(
                    //     Object.fromEntries(
                    //         data.products.map((e) => [e.id, e])
                    //     )
                    // );

                    if (data.error && data.error === "NOT_LOGGED_IN") {
                        navigate(
                            `/admin/login?redirect=${
                                window.location.pathname +
                                window.location.search
                            }`
                        );
                    } else {
                        if (data.loggedIn) {
                            setIsLoading(false);
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            },
            onError: (event) => {
                console.error(event);
            },
            shouldReconnect: (closeEvent) => true,
            reconnectInterval: 3000,
            reconnectAttempts: 50,
        }
    );

    // useEffect(() => {
    //     const isAuth = async () => {
    //         try {
    //             const response = await fetch(`/api/users/authorizedadm`, {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             });

    //             const data = await response.json();

    //             // alert(JSON.stringify(data));

    //             setIsAuth(data.id ? true : false);
    //             setIsLoading(false);

    //             if (data.id) {
    //                 props.setUser(data);
    //             }
    //         } catch (err) {
    //             setTimeout(isAuth, 10000);
    //         }
    //     };
    //     isAuth();
    // }, []);
    // useEffect(() => {
    //     setTimeout(() => setIsLoaded(!isLoading && isAuth), 500);
    // }, [isAuth, isLoading]);

    function printPLPs(orders) {
        return new Promise(async (resolve, reject) => {
            try {
                await fetch(`/api/orders/update`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ids: orders.map((e) => e.id),
                        data: { plpPrinted: true },
                    }),
                });

                let plps = [];

                for await (let o of orders) {
                    try {
                        const plpResponse = await fetch(
                            `/uploads/orders/${o.plp}`
                        );
                        let plpData = await plpResponse.text();
                        if (!plpData) continue;

                        let skus = o.order_products
                            .map(
                                (e) =>
                                    adminProducts.find(
                                        (p) => p.id === e.productId
                                    )?.sku
                            )
                            .join(", ");

                        let plp = plpData;

                        plp = plp.replace("^XA^MCY^XZ", "ZPLAMAZONZPL");
                        plp = plp.replace(
                            /\^XA/i,
                            `^XA ^PW1200 ^BY0,0,0 ^FWN
            ^FO760,18^GFA,104,104,4,,:01C008,03E01E,03F03F,::01E01E,,003FF,01IFE,07JF8,0FC00FE,1FI03F,3EI01F,3EJ0F,3EJ0F83EI01F,1FI03F,1FC007E,07FC7FC,01JF,003FF8,,::^FS
            ^FO25,20^BY2^BCN,23,N,N,N^FD${`${o.id}`.padStart(8, 0)}^FS
            ^FO280,25^A0N,20,20^FD Cod: ${`${o.id}`.padStart(8, 0)}^FS
            ^FO420,25^A0N,20,20^FD SKU: ${
                skus.length > 27 ? "Diversos" : skus
            }^FS ^LH,27`
                        );
                        plp = plp.replace(/\^LH\d+,\d+/i, "$0 ^LH,32");
                        plp = plp.replace(/\^XZ/i, "^LH0,0 ^XZ");
                        plp = plp.replace("ZPLAMAZONZPL", "^XA^MCY^XZ");

                        plps.push(plp);
                    } catch (err) {
                        console.log(err);
                    }
                }

                zebraPrint(plpPrinter, plps.join("\n"));
                resolve("ok");
                return;
            } catch (err) {
                reject(err);
                return;
            }
        });
    }

    function printNFes(orders) {
        return new Promise(async (resolve, reject) => {
            try {
                await fetch(`/api/orders/update`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ids: orders.map((e) => e.id),
                        data: { nfePrinted: true },
                    }),
                });

                for await (let order of orders) {
                    const response = await fetch(
                        `/uploads/orders/${order.nfe}`
                    );
                    const blob = await response.blob();
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        const data = atob(
                            e.target.result.replace(/.*base64,/, "")
                        );
                        renderPage(data, order.id)
                            .then(() => {
                                if (order.id === orders[orders.length - 1].id)
                                    resolve("ok");
                            })
                            .catch((err) =>
                                alert(
                                    `Falha ao imprimir pedido ${order.id}. Verifique a NF.`
                                )
                            );
                    };
                    reader.readAsDataURL(blob);
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    function renderPage(data, orderId) {
        return new Promise(async (resolve, reject) => {
            try {
                const pdf = await pdfjsLib.getDocument({ data }).promise;
                let page = await pdf.getPage(1);
                const canvas = document.createElement("canvas");
                canvas.setAttribute("className", "canv");
                let viewport = page.getViewport({ scale: 2 });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const ctx = canvas.getContext("2d");
                let render_context = {
                    canvasContext: ctx,
                    viewport: viewport,
                };
                await page.render(render_context).promise;
                ctx.font = "bold 30px Montserrat";
                ctx.fillText(
                    `${orderId}`.padStart(8, 0),
                    40,
                    viewport.height - 10
                );
                canvas.toBlob((blob) => {
                    zebraPrint(plpPrinter, blob, "img");
                    canvas.remove();
                    resolve("ok");
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    if (!isLoading) return <>{props.children}</>;
    // if (!isLoading && !isAuth) {
    //     return navigate("/login?redirect=" + path);
    // }

    return (
        <div className="auth-loader">
            <Loader />
        </div>
    );
};

export default Auth;
